import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Button from "@material-ui/core/Button"

import Dropdown from "./Dropdown"
import data from "../assets/data.json"


const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: "transparent"
    },
    navItem: {
        marginLeft: theme.spacing(2),
    },
}))


const Navigation = (props) => {
    const classes = useStyles()

    const NavItem = (props) => (
        <div className={ classes.navItem }>
            { props.children }
        </div>
    )

    return (
        <AppBar position="absolute" className={ classes.appBar } elevation={0}>
            <Toolbar>
                <Box ml="auto" display="flex">
                    <NavItem>
                        <Button href="/" >
                            Home
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button href="/about">
                            About
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Button href="/services">
                            Services
                        </Button>
                    </NavItem>
                    <NavItem>
                        <Dropdown href="/our-work" items={ data.categories }> 
                            Our Work
                        </Dropdown>
                    </NavItem>
                    <NavItem>
                        <Button href="/contact" variant="contained" color="primary">
                            Contact
                        </Button>
                    </NavItem>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default Navigation
