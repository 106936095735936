import React from "react"
import PropTypes from "prop-types"
import clsx from "clsx"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown"


const useStyles = makeStyles(theme => ({
    dropdown: {
        transition: theme.transitions.create(["transform"], {
            duration: theme.transitions.duration.short,
        })
    },
    dropdownOpen: {
        transform: "rotate(-180deg)",
    },
    dropdownClosed: {
        transform: "rotate(0deg)",
    },
}))


const DropdownIndicator = ({ open }) => {
    const classes = useStyles()

    return (
        <KeyboardArrowDown
            className={clsx(classes.dropdown, {
                [classes.dropdownOpen]: open, 
                [classes.dropdowClosed]: !open,
            })}
        />
    )
}


DropdownIndicator.propTypes = {
    open: PropTypes.bool.isRequired,
}


export default DropdownIndicator
