import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"

import DropdownIndicator from "./DropdownIndicator"
import DropdownMenuItem from "./DropdownMenuItem"


const Dropdown = ({ children, ...props}) => {
    const [anchor, setAnchor] = useState(null)
    const open = Boolean(anchor)

    const handleClick = (event) => {
        setAnchor(event.currentTarget)
    }

    const handleClose = () => {
        setAnchor(null)
    }

    return (
        <div>
            <Button 
                onClick={handleClick}
                endIcon={<DropdownIndicator open={open} />}
            >
                { children }
            </Button>
            <Menu 
                open={open}
                anchorEl={anchor}
                onClose={handleClose}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                keepMounted
            >
                { props.items.map(item => (
                    <DropdownMenuItem
                        key={ item.slug }
                        baseUrl={ props.href }
                        {...item}
                    />
                ))}
            </Menu>
        </div>
    )
}

Dropdown.propTypes = {
    items: PropTypes.array.isRequired,
}

export default Dropdown
