import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@material-ui/core/styles"
import MenuItem from "@material-ui/core/MenuItem"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"


const useStyles = makeStyles(theme => ({
    menuItem: {
        textDecoration: "none",
        color: theme.palette.textPrimary,
    }
}))

const DropdownMenuItem = React.forwardRef((props, ref) => {
    const classes = useStyles()

    return (
        <MenuItem
            ref={ ref }
            component={ Link }
            className={ classes.menuItem }
            href={ `/${props.slug}` }
            onClick={props.handleClick}
            dense
        >
            <Typography variant="body2" color="textPrimary">
                { props.title }
            </Typography>
        </MenuItem>
    )
})

DropdownMenuItem.propTypes = {
    baseUrl: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
}

export default DropdownMenuItem
